import React, { useEffect, useState } from "react";

//routing
import { Link } from "react-router-dom";

//redux
import { connect, useDispatch } from "react-redux";

//action
import { createOtp, login } from "../../store/admin/action";
import { useHistory } from "react-router-dom";


const EmailPage = (props) => {
    const dispatch = useDispatch();
    const history = useHistory(); // Use useHistory in v5

    const [email, setEmail] = useState("");
    const [demoLogin, setDemoLogin] = useState();
    const ipAddress = sessionStorage.getItem("ipAddress");

    const isOtpVerified = sessionStorage.getItem("isOtpVerified");

    console.log("isOtpVerified", isOtpVerified)



    console.log("ipAddress", ipAddress)

    const [error, setError] = useState({
        email: "",
    });

    useEffect(() => {
        if (demoLogin) {
            setEmail("");
            setError({
                email: "",
            });
            setDemoLogin(false); // Reset demoLogin state
        }
    }, [demoLogin]);

    useEffect(() => {
        if (isOtpVerified === "true") {
            sessionStorage.setItem("isOtpVerified", false)
        }
    }, [])

    const handleVerifyOtp = (e) => {

        e.preventDefault();

        if (!email) {
            setError({ email: "Email is Required!" });
            return;
        }

        dispatch(createOtp(email))
            .then((response) => {
                // Handle success
                console.log("Response from API:", response);
                history.push("/verifyOtp", { state: { email } });
            })
            .catch((error) => {

                // Handle error
                console.error("Error creating OTP:", error);
            });
    };


    return (
        <>
            <div className="login-page back__style">
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-md-12 col-lg-4">
                            <div class="card login-box-container">
                                <div class="card-body">
                                    <div class="authent-logo mb-4">
                                        <span class="text-danger h1">Ak-Live</span>
                                    </div>
                                    <div class="authent-text">
                                        <p>
                                            Enter your email address.
                                        </p>
                                    </div>

                                    <form autoComplete="off">
                                        <div class="mb-3">
                                            <div class="form-floating">
                                                <input
                                                    type="email"
                                                    class="form-control"
                                                    id="floatingInput"
                                                    placeholder="name@example.com"
                                                    required
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                        if (!e.target.value) {
                                                            return setError({
                                                                ...error,
                                                                email: "Email is Required !",
                                                            });
                                                        } else {
                                                            return setError({
                                                                ...error,
                                                                email: "",
                                                            });
                                                        }
                                                    }}
                                                />
                                                <label for="floatingInput">Email address</label>
                                            </div>
                                            <div class="mt-2 ml-2 mb-3">
                                                {error.email && (
                                                    <div class="pl-1 text-left pb-1">
                                                        <span className="text-red font-size-lg">
                                                            {error.email}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                        <div class="d-grid">
                                            <button
                                                type="submit"
                                                class="btn btn-danger m-b-xs"
                                                onClick={handleVerifyOtp}
                                            >
                                                Verify Otp
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { login })(EmailPage);
