import React, { Suspense, useEffect, useState } from "react";

// routing
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
//types
import { SET_ADMIN, UNSET_ADMIN } from "./store/admin/types";

import { IdleTimeoutManager } from "idle-timer-manager";

//Components
import LoginPage from "./pages/LoginPage";
import UnlockScreenPage from "./pages/UnlockScreenPage";
import Page404 from "./pages/Page404";
import Admin from "./pages/Admin";
import AuthRouter from "./util/AuthRouter";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import axios from "axios";
import Registration from "./pages/Registration";
import UpdateCode from "./pages/UpdateCode";
import Spinner from "./pages/Spinner";
import EmailPage from "./component/table/EmailPage";
import VerifyOtp from "./component/table/VerifyOtp";
import { baseURL } from "./util/Config";
import { apiInstanceFetch } from "./util/api";



function App() {
  // const isOtpVerified = useSelector((state) => state.admin.isOtpVerified)

  let isOtpVerified = sessionStorage.getItem('isOtpVerified')
  const dispatch = useDispatch();
  const isAuth = sessionStorage.getItem("isAuth");
  const [ipAddress, setIpAddress] = useState("");

  const token = sessionStorage.getItem("TOKEN");
  const key = sessionStorage.getItem("KEY");

  useEffect(() => {
    isOtpVerified = sessionStorage.getItem('isOtpVerified')
  }, [isOtpVerified])

  // const [login, setLogin] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get("/login")
  //     .then((res) => {
  //       console.log("res.data", res.data);
  //       setLogin(res.data.login);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    if (token && key) {
      if (isAuth) {
        dispatch({ type: SET_ADMIN, payload: token });
      }
    }
  }, [dispatch, isAuth, token, key]);


  // const getPublicIP = async () => {
  //   const rtcPeerConnection = new RTCPeerConnection({
  //     iceServers: [{ urls: "stun:stun.l.google.com:19302" }] // Use a public STUN server
  //   });

  //   rtcPeerConnection.createDataChannel(""); // Create a dummy data channel

  //   rtcPeerConnection.onicecandidate = (event) => {
  //     if (event.candidate) {
  //       const candidate = event.candidate.candidate;
  //       const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
  //       const ip = candidate.match(ipRegex)?.[0];
  //       sessionStorage.setItem("ipAddress", ip);
  //       if (ip) {
  //         console.log("Public IP Address:", ip);
  //         rtcPeerConnection.close(); // Close the connection after fetching the IP
  //       }
  //     }
  //   };

  //   await rtcPeerConnection.createOffer()
  //     .then((offer) => rtcPeerConnection.setLocalDescription(offer))
  //     .catch(console.error);
  // };


  // useEffect(() => {
  //   getPublicIP();
  // }, []);




  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout: 1800, //30 min (in sec)
      onExpired: (time) => {
        dispatch({ type: UNSET_ADMIN });
        return (window.location.href = "/");
      },
    });

    return () => {
      manager.clear();
    };
  }, []);

  return (
    <div className="App">
      <Suspense fallback={""}>
        <BrowserRouter>
          <Switch>
            {/* <AuthRouter exact path="/" component={login ? Login : Registration} /> */}
            <AuthRouter exact path="/" component={EmailPage} />
            {/* <AuthRouter exact path="/code" component={UpdateCode} /> */}

            <AuthRouter path="/loginPage" component={LoginPage} />

            {/* <AuthRouter path="/Registration" component={Registration} /> */}
            <AuthRouter exact path="/unlock" component={UnlockScreenPage} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route
              exact
              path="/changePassword/:id"
              component={ChangePassword}
            />
            {isAuth && <Route path="/admin" component={Admin} />}

            <Route
              exact
              path="/verifyOtp"
              component={VerifyOtp}
            />

            <Route component={Page404} />
          </Switch>
          <Spinner />
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
