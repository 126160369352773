import React, { useEffect, useState } from "react";
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
//MUI
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types

//action
import {
    addCoinSeller,
    editCoinSeller,
    getCoinSellerUniqueId,
} from "../../store/coinSeller/action";
import { CLOSE_DIALOGUE } from "../../store/coinSeller/type";
import { CLOSE_SETTING_DIALOGUE } from "../../store/setting/types";
import { createNewIpAddress, getIpAddressWhilteList } from "../../store/admin/action";
import { CLOSE_IP_DIALOG } from "../../store/admin/types";
import { warning } from "../../util/Alert";

const IpAddressDialogue = (props) => {
    const dispatch = useDispatch();

    const { dialogIp: open } = useSelector(
        (state) => state.admin
    );
    const { coinSellerId } = useSelector((state) => state.coinSeller);
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [ipAddress, setIpAddress] = useState("");

    console.log("data", data);
    console.log("coinSellerId", coinSellerId);

    const [errors, setError] = useState({
        uniqueId: "",
        coin: "",
        mobileNumber: "",
    });


    useEffect(() => {
        setData(coinSellerId);
    }, [coinSellerId]);


    useEffect(() => {
        setIpAddress("")
    }, [open]);

    const closePopup = () => {
        dispatch({ type: CLOSE_IP_DIALOG });
    };

    const handleSubmit = () => {
        if (!ipAddress) {
            const error = {};
            if (!ipAddress) error.ipAddress = "Ip Address Is Required !";
            return setError({ ...error });
        } else {
            const data = {
                ipAddress: ipAddress
            };

            dispatch(createNewIpAddress(data));
            closePopup();
        }
    };

 

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="responsive-dialog-title"
                onClose={closePopup}
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="responsive-dialog-title">
                    <span className="text-danger font-weight-bold h4"> Ip Address </span>
                </DialogTitle>

                <IconButton
                    style={{
                        position: "absolute",
                        right: 0,
                    }}
                >
                    <Tooltip title="Close">
                        <Cancel className="text-danger" onClick={closePopup} />
                    </Tooltip>
                </IconButton>
                <DialogContent>
                    <div className="modal-body pt-1 px-1 pb-3">
                        <div className="d-flex flex-column">
                            <form>
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className=" form-group">
                                            <label className="mb-2 mt-3 text-gray">IP Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required=""
                                                placeholder="Enter Ip Address"
                                                value={ipAddress}
                                                onChange={(e) => {
                                                    setIpAddress(e.target.value);
                                                    if (!e.target.value) {
                                                        return setError({
                                                            ...errors,
                                                            ipAddress: "Ip Address is Required!",
                                                        });
                                                    } else if (e.target.value < 0) {
                                                        return setError({
                                                            ...errors,
                                                            ipAddress: "Enter Correct Coin !",
                                                        });
                                                    } else {
                                                        return setError({
                                                            ...errors,
                                                            ipAddress: "",
                                                        });
                                                    }
                                                }}
                                            />
                                            {errors.ipAddress && (
                                                <div className="ml-2 mt-1">
                                                    {errors.ipAddress && (
                                                        <div className="pl-1 text__left">
                                                            <span className="text-red">{errors.ipAddress}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <span style={{ color: "red", paddingTop: "20px" }}>
                                                Note : Enter IpAddress coma (,) separated string.
                                            </span>
                                           
                                        </div>
                                    </div>

                                </div>
                                <div className={"mt-5"}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                                        onClick={closePopup}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-round float__right btn-danger"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default connect(null, { addCoinSeller, editCoinSeller })(IpAddressDialogue);
