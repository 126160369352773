import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  getAgency,
  enableDisableAgency,
  redeemEnableAgency,
  editAgencyCoin,
} from "../store/agency/action";

//routing
import $ from "jquery";
import { Link } from "react-router-dom";
import Male from "../assets/images/male.png";
//MUI
import { Tooltip } from "@material-ui/core";

import AgencyDialogue from "../component/dialog/AgencyDialogue";

//sweet alert
import { alert, warning } from "../util/Alert";
import { OPEN_AGENCY_DIALOG } from "../store/agency/type";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "./Pagination";
import EdiText from "react-editext";
import ActiveAgency from "../component/table/agency/ActiveAgency";
import InActiveAgency from "../component/table/agency/InActiveAgency";

const AgencyDetails = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const history = useHistory();
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [searchString, setSearchString] = useState("all");
  const [type, setType] = useState("Active");


  useEffect(() => {
    dispatch(getAgency(activePage, rowsPerPage, searchString));
  }, [activePage, rowsPerPage]);

  const { agency, total } = useSelector((state) => state.agency);

  console.log("agency", agency);

  useEffect(() => {
    setData(agency);
  }, [agency]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });

  const handleEdit = (data) => {
    dispatch({ type: OPEN_AGENCY_DIALOG, payload: data });
  };

  const handleIsTop = (id) => {
    dispatch(enableDisableAgency(id));
  };

  function formatNumber(num) {
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1) + "T"; // Trillions
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + "B"; // Billions
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + "M"; // Millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString(); // If less than 1,000, show the number as is
    }
  }

  const handleEditRCoin = (newValue, type, agencyId) => {
    try {
      if (type === "rCoin") {
        const data = {
          rCoin: parseInt(newValue),
        };
        // Optionally, update the server if needed
        dispatch(editAgencyCoin(data, agencyId));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Agency</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Agency
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="my-2">
              <button
                type="button"
                className={`btn btn-sm ${
                  type === "Active" ? "btn-info" : "disabledBtn"
                }`}
                onClick={() => setType("Active")}
              >
                <span className="">Active</span>
              </button>
              <button
                type="button"
                className={`btn btn-sm ${
                  type === "inActive" ? "btn-danger" : "disabledBtn"
                } ms-3`}
                onClick={() => setType("inActive")}
              >
                <span className="">inActive</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {type === "Active" ? <ActiveAgency /> : <InActiveAgency />}
      <AgencyDialogue />
    </>
  );
};

export default connect(null, { getAgency, enableDisableAgency })(AgencyDetails);
