import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTaskHistory } from "../store/hostTask/action";
import Pagination from "./Pagination";

function convertMinutesToTime(minutes) {
  const totalSeconds = Math.floor(minutes * 60);
  const hours = Math.floor(totalSeconds / 3600);
  const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Construct an array and filter out zero values
  const timeParts = [];
  if (hours > 0) timeParts.push(`${hours} hrs`);
  if (remainingMinutes > 0) timeParts.push(`${remainingMinutes} mins`);
  if (seconds > 0) timeParts.push(`${seconds} secs`);

  // Join the array into a single string
  return timeParts.join(", ");
}

const formatDateAndTime = (dateTimeString) => {
  const dateObject = new Date(dateTimeString);
  const date = dateObject.toLocaleDateString(); // e.g., "1/18/2025"
  const time = dateObject.toLocaleTimeString(); // e.g., "5:12:16 PM"
  return { date, time };
};

const HostTaskHistory = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const { taskHistory, total } = useSelector((state) => state.hostTask);

  console.log(total)

  useEffect(() => {
    dispatch(getTaskHistory(activePage, rowsPerPage, search));
  }, [dispatch, activePage, rowsPerPage]);

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleSearch = (search) => {
    setActivePage(1);
    const searchString = search.trim().toLowerCase();
    dispatch(getTaskHistory(activePage, rowsPerPage, searchString));
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Host Task History</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Host Task History
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  {/* <form action=""> */}
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSearch(e.target.value)}
                      />
                      <div
                        className="input-group-prepend border-0"
                        onClick={handleSearch}
                      >
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
            <div class="card-body card-overflowone pt-0">
              <table class="table table-striped card-overflow mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>UniqueId</th>
                    <th>Task Type</th>
                    <th>Duration</th>
                    <th>Required Coin</th>
                    <th>Reward Coin</th>
                    <th>Time</th>
                    <th>date</th>
                  </tr>
                </thead>
                <tbody>
                  {taskHistory.length > 0 ? (
                    taskHistory.map((data, index) => {
                      const { date, time } = formatDateAndTime(data.date);
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.user.name}</td>
                          <td>{data.user.uniqueId}</td>
                          <td>{data.task?.type || "N/A"}</td>
                          <td>
                            {convertMinutesToTime(data.task?.timeRequired) || "N/A"}
                          </td>

                          <td>{data.task?.coinRequired || "N/A"}</td>
                          <td>{data.rCoin || "N/A"}</td>
                          <td>{time}</td>
                          <td>{date}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostTaskHistory;
