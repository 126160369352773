import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { EDIT_EMAIL_CLOSE_DIALOGUE } from "../../store/coinSeller/type";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { EditEmail } from "../../store/coinSeller/action";

const EmailEditModel = (props) => {
  const { emailDialogOpen: open, emailDialogData } = useSelector(
    (state) => state.coinSeller
  );
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    email: "",
  });

  useEffect(() => {
    console.log('emailDialogData', emailDialogData)
    setEmail(emailDialogData?.email);
    setError("");
  }, [open, emailDialogData]);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email) {
      return setError({ email: "Email Is Required!" });
    } else if (!emailRegex.test(email)) {
      return setError({ email: "Invalid Email Address!" });
    } else {
      let data = {
        email: email,
      };

      props.EditEmail(data, emailDialogData?._id);
      dispatch({ type: EDIT_EMAIL_CLOSE_DIALOGUE });
    }
  };

  const closePopup = () => {
    dispatch({ type: EDIT_EMAIL_CLOSE_DIALOGUE });
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">Coin Seller</span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="form-group">
                  <label className="mb-2 text-gray">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    required=""
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          email: "Email is Required!",
                        });
                      } else {
                        return setError({
                          email: "",
                        });
                      }
                    }}
                  />
                </div>
                {error.email && (
                  <div className="ml-2 mt-1">
                    <div className="pl-1 text__left">
                      <span className="text-red">{error.email}</span>
                    </div>
                  </div>
                )}
                <div className="mt-3">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { EditEmail })(EmailEditModel);