import axios from "axios";
import { Toast } from "../../util/Toast";

import { CREATE_IP_ADDRESS, DELETE_IP_ADDRESS, GET_IP_ADDRESS, IS_VERIFIED_OTP, SET_ADMIN, SIGNUP_ADMIN, UNSET_ADMIN, UPDATE_PROFILE } from "./types";
import { apiInstanceFetch } from "../../util/api";

export const signupAdmin = (signup) => (dispatch) => {
  axios
    .post("/admin/signup", signup)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        dispatch({ type: SIGNUP_ADMIN });
        Toast("success", "Signup Successfully!");
        setTimeout(() => {
          window.location.href = "/loginPage";
        }, 10);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error);
      console.log(error);
    });
};

export const login = (data) => (dispatch) => {

  axios
    .post("admin/login", data)
    .then((res) => {
      // 
      console.log("res", res)

      if (res.data.status) {
        Toast("success", "You have successfully logged in Ak-Live.");
        setTimeout(() => {
          window.location.href = "/admin/dashboard";
        }, 2000);
        dispatch({ type: SET_ADMIN, payload: res.data.token });
      }
      else {

        Toast("error", res.data.message);
      }
    })
    .catch((error) => {

      Toast("error", error.message);
      setTimeout(() => {
        window.location.href = "/";
      }, 2000)
    });
};

export const sendEmail = (data) => (dispatch) => {
  axios
    .post("admin/sendEmail", data)
    .then((res) => {
      if (res.data.status) {
        Toast(
          "success",
          "Mail has been sent successfully. Sometimes mail has been landed on your spam!"
        );
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const createOtp = (email) => (dispatch) => {
  return axios
    .post(`otp/authCode?email=${email}`)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Email has been sent successfully");
        return res.data; // Return the response data
      } else {
        Toast("error", res.data.message);
        throw new Error(res.data.message); // Throw an error for non-success status
      }
    })
    .catch((error) => {
      throw error; // Re-throw the error so it can be caught later
    });
};

export const verifyOtpForAuth = (data) => (dispatch) => {
  return axios
    .post(`otp/authenticateCode`, data)
    .then((res) => {

      if (res.data.status) {

        Toast(
          "success",
          res.data.message
        );
        dispatch({ type: IS_VERIFIED_OTP });
        sessionStorage.setItem("isOtpVerified", true)
        return res.data
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const getProfile = () => (dispatch) => {
  apiInstanceFetch
    .get("admin/profile")
    .then((res) => {

      if (res.status) {
        dispatch({ type: UPDATE_PROFILE, payload: res.admin });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const getIpAddressWhilteList = () => (dispatch) => {
  apiInstanceFetch
    .get("IpWhitelist/getIpAddresses")
    .then((res) => {


      if (res?.status) {
        dispatch({ type: GET_IP_ADDRESS, payload: res?.ipAddresses });
      } else {
        Toast("error", res?.message);
      }
    })
    .catch((error) => {
      console.log("error", error?.message);
    });
};

export const createNewIpAddress = (data) => (dispatch) => {
  axios
    .post(`IpWhitelist/addIpAddresses`, data)
    .then((res) => {

      if (res?.data?.status) {
        Toast("success", "Ip Address created successfully!");
        dispatch({ type: CREATE_IP_ADDRESS, payload: res?.data?.addedIPs });
      } else {
        Toast("error", res?.data?.message);
      }
    })
    .catch((error) => Toast("error", error?.message));
};



export const deleteNewIpAddress = (data) => (dispatch) => {
  axios
    .patch(`/IpWhitelist/removeIpAddress`, { ipAddress: data })
    .then((res) => {

      if (res?.data?.status) {
        Toast("success", "IpAddress Deleted successfully!");
        dispatch({ type: DELETE_IP_ADDRESS, payload: res.data.removedIP });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const changePassword = (data) => (dispatch) => {
  axios
    .put("admin", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_ADMIN });
          window.location.href = "/";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const updateNameEmail = (data) => (dispatch) => {
  axios
    .patch("admin", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({ type: UPDATE_PROFILE, payload: res.data.admin });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};


export const updateCode = (signup) => (dispatch) => {
  axios
    .patch("admin/updateCode", signup)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        console.log("res.data", res.data);
        Toast("success", "Purchase Code Update Successfully !");
        setTimeout(() => {
          window.location.href = "/loginPage";
        }, 3000);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error);
      console.log(error);
    });
};
