import React, { useEffect, useRef } from "react";
import SVGA from "svgaplayerweb";

const SVGAComponent = ({ src, index }) => {
  const playerRef = useRef(null);
  const blobUrlRef = useRef(null);

  useEffect(() => {
    let player = null;
    const elementId = `svga-${index}`;

    const initSVGA = async () => {
      try {
        // Fetch the SVGA file and create a blob URL
        const response = await fetch(src);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        blobUrlRef.current = blobUrl;
        console.log(blobUrl)
        // Create player instance
        player = new SVGA.Player(`#${elementId}`);
        playerRef.current = player;

        const parser = new SVGA.Parser();

        // Load and play animation using blob URL
        await new Promise((resolve, reject) => {
          parser.load(
            blobUrl,
            (videoItem) => {
              try {
                if (player) {
                  player.setVideoItem(videoItem);
                  player.startAnimation();
                  resolve();
                }
              } catch (err) {
                console.error("Error playing SVGA:", err);
                reject(err);
              }
            },
            (err) => {
              console.error("Error loading SVGA:", err);
              reject(err);
            }
          );
        });
      } catch (error) {
        console.error("SVGA initialization error:", error);
      }
    };

    initSVGA();

    // Cleanup function
    return () => {
      if (playerRef.current) {
        playerRef.current.stopAnimation();
        playerRef.current.clear();
        playerRef.current = null;
      }
      // Clean up blob URL
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
        blobUrlRef.current = null;
      }
    };
  }, [src, index]);

  return (
    <div
      id={`svga-${index}`}
      style={{
        width: "135px",
        height: "135px",
        objectFit: "contain",
      }}
      className="rounded-circle my-auto"
    />
  );
};

export default SVGAComponent;
