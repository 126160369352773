import React, { useEffect, useState } from "react";

//routing
import { Link } from "react-router-dom";

//redux
import { connect, useDispatch } from "react-redux";

//action
import { createOtp, login, verifyOtpForAuth } from "../../store/admin/action";
import { useLocation, useHistory } from "react-router-dom";
import { Toast } from "../../util/Toast";


const VerifyOtp = (props) => {
    const dispatch = useDispatch();
    const [otp, setOtp] = useState("");
    // const [verifyOtp, setVerifyOtp] = useState("");
    const [demoLogin, setDemoLogin] = useState();
    const location = useLocation();
    const history = useHistory();

    const isOtpVerified = sessionStorage.getItem("isOtpVerified");

    console.log("location", location)

    const [error, setError] = useState({
        otp: "",
        // verifyOtp: "",
    });

    useEffect(() => {
        if (demoLogin) {
            setOtp("");
            // setVerifyOtp("")
            setError({
                email: "",
            });
            setDemoLogin(false);
        }
    }, [demoLogin]);




    const handleVerifyOtp = (e) => {
        e.preventDefault();

        if (!otp) {
            const error = {};
            if (!otp) error.otp = "Otp is Required!";
            return setError({ ...error });
        }

        const data = {
            email: location?.state?.state?.email,
            otp: parseInt(otp)
        };

        if (data) {
            dispatch(verifyOtpForAuth(data))
                .then((response) => {
                    console.log("response", response);
                    if (response) {
                        Toast("success", response?.data?.messages);

                        // Check if OTP was verified successfully and sessionStorage value is 'true'

                        // Using useHistory hook for navigation
                        setTimeout(() => {
                            history.push("/loginPage");
                        }, 1000)

                    }
                })
                .catch((error) => {
                    console.error("Error creating OTP:", error);
                });
        }
    };

    return (
        <>
            <div className="login-page back__style">
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-md-12 col-lg-4">
                            <div class="card login-box-container">
                                <div class="card-body">
                                    <div class="authent-logo mb-4">
                                        <span class="text-danger h1">Ak-Live</span>
                                    </div>
                                    <div class="authent-text">
                                        <p>
                                            Verify Your Otp
                                        </p>
                                    </div>

                                    <form autoComplete="off">
                                        <div class="mb-3">
                                            <div class="form-floating">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="floatingInput"
                                                    placeholder="Enter Otp"
                                                    required
                                                    value={otp}
                                                    onChange={(e) => {
                                                        setOtp(e.target.value);
                                                        if (!e.target.value) {
                                                            return setError({
                                                                ...error,
                                                                otp: "Otp is Required !",
                                                            });
                                                        } else {
                                                            return setError({
                                                                ...error,
                                                                otp: "",
                                                            });
                                                        }
                                                    }}
                                                />
                                                <label for="floatingInput">Enter Otp</label>
                                            </div>
                                            <div class="mt-2 ml-2 mb-3">
                                                {error.otp && (
                                                    <div class="pl-1 text-left pb-1">
                                                        <span className="text-red font-size-lg">
                                                            {error.otp}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* <div class="mb-3">
                                            <div class="form-floating">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="floatingInput"
                                                    placeholder="Verify Otp"
                                                    required
                                                    value={verifyOtp}
                                                    onChange={(e) => {
                                                        setVerifyOtp(e.target.value);
                                                        if (!e.target.value) {
                                                            return setError({
                                                                ...error,
                                                                verifyOtp: "Verify Otp is Required !",
                                                            });
                                                        } else {
                                                            return setError({
                                                                ...error,
                                                                verifyOtp: "",
                                                            });
                                                        }
                                                    }}
                                                />
                                                <label for="floatingInput">Verify Otp</label>
                                            </div>
                                            <div class="mt-2 ml-2 mb-3">
                                                {error.verifyOtp && (
                                                    <div class="pl-1 text-left pb-1">
                                                        <span className="text-red font-size-lg">
                                                            {error.verifyOtp}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div> */}


                                        <div class="d-grid">
                                            <button
                                                type="submit"
                                                class="btn btn-danger m-b-xs"
                                                onClick={handleVerifyOtp}
                                            >
                                                Verify Otp
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, { login })(VerifyOtp);
