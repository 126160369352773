export const SET_ADMIN = "SET_ADMIN";
export const UNSET_ADMIN = "UNSET_ADMIN";

export const SEND_EMAIL = "SEND_EMAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const OPEN_ADMIN_DIALOG = "OPEN_ADMIN_DIALOG"
;
export const CLOSE_ADMIN_DIALOG = "CLOSE_ADMIN_DIALOG";
export const OPEN_IP_DIALOG = "OPEN_IP_DIALOG"
export const CLOSE_IP_DIALOG = "CLOSE_IP_DIALOG";


export const GET_IP_ADDRESS = "GET_IP_ADDRESS";
export const CREATE_IP_ADDRESS = "CREATE_IP_ADDRESS";
export const DELETE_IP_ADDRESS = "DELETE_IP_ADDRESS";

export const IS_VERIFIED_OTP = "IS_VERIFIED_OTP";






export  const SIGNUP_ADMIN ="SIGNUP_ADMIN"
