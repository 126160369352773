import {
  CLOSE_SPINNER_PROGRESS,
  OPEN_SPINNER_PROGRESS,
} from "../store/spinner/types";
import { baseURL, key } from "./Config";
import store from "../store/provider";
import { Toast } from "./Toast";

export const openSpinner = () => {
  return store.dispatch({ type: OPEN_SPINNER_PROGRESS });
};

export const closeSpinner = () => {
  return store.dispatch({ type: CLOSE_SPINNER_PROGRESS });
};
const token = sessionStorage.getItem("TOKEN");
const ipAddress = sessionStorage.getItem("ipAddress");
export const apiInstanceFetch = {
  baseURL: `${baseURL}`, // Set your default base URL here
  headers: {
    "Content-Type": "application/json",
    key: `${key}`,
    Authorization: `${token}`,
    "x-forwarded-for": ipAddress,
  },
  get: (url) => {
    openSpinner();
    return fetch(`${apiInstanceFetch?.baseURL}${url}`, {
      method: "GET",
      headers: apiInstanceFetch?.headers,
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  post: (url, data) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "POST",
      headers: { ...apiInstanceFetch.headers },
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  patch: (url, data) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "PATCH",
      headers: { ...apiInstanceFetch.headers },
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  put: (url, data) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "PUT",
      headers: { ...apiInstanceFetch.headers },
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },

  delete: (url) => {
    openSpinner();
    return fetch(`${apiInstanceFetch.baseURL}${url}`, {
      method: "DELETE",
      headers: apiInstanceFetch.headers,
    })
      .then(handleErrors)
      .finally(() => closeSpinner());
  },
};


let errorHandled = false; // Global flag to ensure error is handled once

function handleErrors(response) {
  if (!response.ok) {
    const preservedValue = sessionStorage.getItem("isOtpVerified");
    // LocalStorage Has been Clear :
    sessionStorage.clear();

    if (preservedValue !== null) {
      sessionStorage.setItem("isOtpVerified", preservedValue);
    }

    // Status Code is 403 at that time take a some action :-
    if (response.status === 403) {
      if (!errorHandled) {
        errorHandled = true;
        Toast("error", "Forbidden: Your IP is not whitelisted.");
        setTimeout(() => {
          errorHandled = false;
          window.location.href = "/";
        }, 2000);
      }

      // Status Code is 401 at that time take a some action :-
      // 
    } if (response.status === 401) {
      window.location.href = "/";
    }

    else {

      // Reset Status Code :
      if (!errorHandled) {
        errorHandled = true;
        Toast("error", `HTTP error! Status: ${response.status}`);
        setTimeout(() => {
          errorHandled = false;
        }, 2000);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    }
  }

  return response.json();
}

